#root {
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: auto 40% 20%;
}
.father-grid {
  height: 500px;
  margin-left: -15px;
}
border {
  border: 1px solid;
}
.big-many-boxes {
  padding: 15px;
  grid-template-columns: 50% 50%;
  grid-gap: 15px;
  grid-template-rows: auto auto auto;
}
.big-logo {
  padding: 15px;
  justify-content: center;
}
.home-card {
  display: grid;
  margin-bottom: 0px;
  border: 1px solid black(190, 0, 0);
}

.home-card:hover {
  border: 1px solid red;
  transition: 1.8s;
}
.home-icon {
  font-size: 36px;
  align-content: center;
  align-items: center;
  align-self: center;
}
.first-grid {
  padding: 15px;
  grid-template-rows: auto;
  grid-template-columns: auto;
}

.last-grid {
  grid-gap: 15px;
  padding: 15px;
  grid-template-rows: 68.7% auto;
  grid-template-columns: auto;
}

.home-card-span {
  display: block;
  padding-top: 10;
  height: inherit;
  width: inherit;
}

.home-card-settings {
  background-color: lightgray;
}

a {
  color: inherit;
  font-style: inherit;
  text-decoration: none;
  align-content: center;
  align-items: center;
  align-self: center;
}

a:hover {
  text-decoration: inherit;
  text-decoration-color: inherit;
  transition: 1.5s;
  color: rgb(255, 0, 0);
}

.home-card small,
.small {
  font-size: 68.5%;
  font-weight: 400;
}
.home-card .custom-control-label {
  font-size: 12px;
}
.home-card .alert {
  margin-bottom: 0;
}

.col-login {
  max-width: 24rem;
  padding-top: 111px;
}
.col-login img {
  height: 45px !important;
}

.btn-new {
  float: left;
  font-size: 1.15em;
  margin-bottom: 1%;
}

.badges {
  font-size: 0.9em;
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
}

.c3 path,
.c3 line {
  fill: none;
  stroke: rgba(0, 40, 100, 0.12);
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: px2rem(12px);
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #f0f0f0;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid,
.c3-ygrid {
  stroke: #e6e6e6;
  stroke-dasharray: 2 4;
}

/*-- Text on Chart --*/
.c3-text {
  font-size: 12px;
}

.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 2px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 2px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 1.5px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item text {
  fill: #545454;
  font-size: 14px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  fill: transparent;
  stroke: lightgray;
  stroke-width: 0;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  background: #212529 !important;
  white-space: nowrap;
}

.c3-tooltip th {
  padding: 6px 6px;
  text-align: left;
}

.c3-tooltip td {
  padding: 4px 6px;
  font-weight: 400;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  vertical-align: baseline;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.1;
}

.c3-target-filled .c3-area {
  opacity: 1 !important;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3-axis {
  fill: #9aa0ac;
}

.PurchasesDashboard-Card {
  height: 302.3px;
  align-content: center;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
.modal-65w {
  width: 62%;
  max-width: none !important;
}

.btn-pill {
  margin-bottom: 10px;
  margin-left: 5px;
}

.modal-header {
  padding: 10px 20px 10px 10px;
}

.card-options button {
  margin-left: 5px;
}
.no-border .card {
  padding-top: 0px;
  border: none;
  box-shadow: none;
}
.no-border .card-header {
  padding-top: 0px;
  border: none;
}
.no-border .modal-body {
  padding: 0px;
}
.nav-tabs {
  width: 100%;
}
button.circular.btn.btn-primary {
  border-radius: 50%;
}

.react-dropdown-select-dropdown {
  top: 43px !important;
  width: 354px !important;
}
.input-group-append {
  margin-top: 27px;
  margin-bottom: 16px;
  margin-left: -1px;
}
.input-group .form-group {
  width: 89%;
}
.show-only-on-print {
  display: none;
}
td.ellipsis {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th.delete {
  width: 59px;
}

th.with-100 {
  width: 100px;
}
th.with-150 {
  width: 150px;
}
a.dropdown-item {
  cursor: pointer;
}
.query-subject {
  width: 100%;
  margin-bottom: 15px;
}
.query-input input {
  height: 38px;
  margin-top: 27px;
}
@media print {
  div.printDiv {
    padding-top: 50px;
  }
  .hidden-on-print {
    display: none;
  }
  .show-only-on-print {
    display: block;
  }
}
